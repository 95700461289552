h2 {
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 1em;
    padding-top: 1em;
}

p {
    padding-bottom: 1em;
    color: #ffffff96;
}

ul {
    padding-bottom: 1em;
    color: #ffffff96;
    list-style-type: disc;
    padding-left: 2em;
}

li {
    padding-bottom: 8px;
}

b {
    color: #ac1616;
    font-weight: 400;
}